<!--
 * @Description: 班级详情
 * @Author: xiawenlong
 * @Date: 2021-05-29 16:14:21
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-09-28 17:15:41
-->
<template>
  <div class="class-detail">
    <el-card class="class-info">
      <div slot="header" class="clearfix">
        <span class="title">班级信息</span>
      </div>
      <div class="content">
        <div class="cover">
          <img :src="classInfo.pic" alt="" />
        </div>
        <div class="text">
          <p class="name">班级名称：{{ classInfo.className }}</p>
          <p class="teacher">
            班主任：<span v-for="(item, index) in classInfo.headTeacher" :key="index">{{
              item.studentName
            }}</span>
          </p>
          <p class="time">
            班级培训周期：{{ classInfo.classBeginTime }}~{{ classInfo.classEndTime }}
          </p>
        </div>
      </div>
    </el-card>
    <el-card class="student-info">
      <div slot="header" class="clearfix">
        <span class="title">员工信息</span>
      </div>
      <div class="content">
        <table-list
          :search-form="searchForm(this)"
          :data="studentList"
          :columns="columns(this)"
          :pager="studentPager"
          @search="onSearch"
          @size-change="handleStudentSizeChange"
          @current-change="handleStudentCurrentChange"
        >
        </table-list>
      </div>
    </el-card>
    <el-card class="course-list">
      <div slot="header" class="clearfix">
        <span class="title">课程信息</span>
      </div>
      <div class="content">
        <div
          v-for="item in courseList"
          :key="item.courseId"
          class="course-item"
          @click="toPlay(item)"
        >
          <div class="badge">
            <img src="@/assets/shop/lubo_icon.png" alt="" />
          </div>
          <div class="cover">
            <img :src="item.pic" alt="" />
          </div>
          <div class="text">
            <div class="name">{{ item.courseName }}</div>
            <div class="info">
              {{ item.chapterCount }}节丨{{ item.durationTotal }}分钟丨{{ item.organizationName }}
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        background
        :current-page="coursePager.current"
        :page-size="coursePager.size"
        layout="total, prev, pager, next, jumper"
        :total="coursePager.total"
        @current-change="handleCourseCurrentChange"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
// 搜索框配置
const searchForm = () => [
  {
    label: '员工名称',
    prop: 'studentName',
    width: 120,
  },
  {
    label: '员工手机号',
    prop: 'mobile',
    width: 120,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '员工名称',
  },
  {
    prop: 'mobile',
    label: '手机号码',
  },
  {
    prop: 'cardNo',
    label: '身份证/护照',
  },
]
import { classInfo, classDetailStudents, classDetailCourse } from '@/api/class'
import to from 'await-to'
import TableList from '@/components/TableList'
export default {
  name: 'ClassDetail',
  components: {
    TableList,
  },
  data() {
    return {
      searchForm,
      columns,
      classId: '',
      classInfo: {},
      queryParams: {},
      studentList: [],
      studentPager: {
        total: 0,
        size: 10,
        current: 1,
      },
      courseList: [],
      coursePager: {
        total: 0,
        size: 10,
        current: 1,
      },
    }
  },
  mounted() {
    this.classId = this.$route.params.classId
    this.getClassInfo()
    this.getStudent()
    this.getCourseList()
  },
  methods: {
    async getClassInfo() {
      const [res, err] = await to(classInfo({ classId: this.classId }))
      if (err) return this.$message.warning(err.msg)
      this.classInfo = res.data
    },
    async getStudent() {
      const { current, size } = this.studentPager
      const { classId } = this
      const [res, err] = await to(
        classDetailStudents({ classId, current, size, ...this.queryParams }),
      )
      if (err) return this.$message.warning(err.msg)
      this.studentList = res.data.list
      this.studentPager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.studentPager.current = 1
      this.queryParams = queryParams
      this.getStudent()
    },
    // 分页
    handleStudentSizeChange(size) {
      this.studentPager.current = 1
      this.studentPager.size = size
      this.getStudent()
    },
    handleStudentCurrentChange(current) {
      this.studentPager.current = current
      this.getStudent()
    },
    async getCourseList() {
      const { current, size } = this.coursePager
      const { classId } = this
      const [res, err] = await to(classDetailCourse({ classId, current, size }))
      if (err) return this.$message.warning(err.msg)
      this.courseList = res.data.list
      this.coursePager.total = res.data.total
    },
    handleCourseCurrentChange(current) {
      this.coursePager.current = current
      this.getCourseList()
    },
    toPlay(item) {
      if (this.classInfo.trainFlag) {
        this.$router.push(
          `/class/play?classId=${this.classId}&courseId=${item.courseId}&courseName=${item.courseName}&duration=${item.durationTotal}`,
        )
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.class-detail {
  .el-card {
    margin-bottom: 20px !important;
    &.class-info {
      .content {
        display: flex;
        align-items: center;
        .cover {
          width: 282px;
          height: 145px;
          margin-right: 20px;
          img {
            @extend %width-height;
          }
        }
        .text {
          height: 145px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          p {
            font-size: 16px;
            color: #333333;
          }
        }
      }
    }
  }
  .course-list {
    ::v-deep.el-card__body {
      padding-right: 0;
    }

    ::v-deep.el-pagination {
      margin-right: 20px;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      .course-item {
        position: relative;
        cursor: pointer;
        box-sizing: border-box;
        overflow: hidden;
        width: 282px;
        border-radius: 4px;
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.06);
        margin-right: 23px;
        margin-bottom: 24px;
        transition: transform 0.3s;
        background-color: #fff;
        .cover {
          width: 282px;
          height: 145px;
          img {
            @extend %width-height;
          }
        }
        .text {
          box-sizing: border-box;
          width: 282px;
          padding: 14px 10px;
          .name {
            @extend %one-line-text;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 7px;
          }
          .info {
            font-size: 12px;
            color: #666666;
            height: 12px;
            margin-bottom: 10px;
          }
          .price {
            line-height: 24px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            span {
              &:nth-of-type(1) {
                font-size: 24px;
                color: #ff583e;
              }
              &:nth-of-type(2) {
                line-height: 12px;
                font-size: 12px;
                color: #666666;
              }
            }
          }
        }
        .badge {
          position: absolute;
          width: 56px;
          height: 22px;
          left: 10px;
          top: 10px;
          img {
            @extend %width-height;
          }
        }
      }
    }
  }
}
</style>
